'use client'

import { FC, useEffect } from 'react'
import { usePathname } from 'next/navigation'

export const ScrollTop: FC = () => {

    const pathname = usePathname()

    useEffect(() => {
        if (!window.location.hash) {
            window.scroll(0,0)
        } else {
            const id = window.location.hash.slice(1)
            const element = document.getElementById(id)
            const header = document.getElementsByTagName('header')[0]
            if (element) {
                element.scrollIntoView()
                if ( header?.clientHeight !== undefined ) {
                    window.scrollBy(0, -(header.clientHeight + 20))
                } else {
                    window.scrollBy(0, -20)
                }
            }
        }
    }, [pathname])

    return <></>

}
